import React from "react"
import Layout from "../components/layout"
import SectionTitle from "../components/common/SectionTitle"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledPage = styled.div`
  max-width: 1400px;
  margin: 5rem auto;
`

const ContactSucsessPage = () => {
  return (
    <Layout>
      <StyledPage>
        <SectionTitle title="Thank you for contacting Geek Life Radio" />
        <h2>Your message has been successfully submitted.</h2>
        <p>Please allow upto 72 hours for reply.</p>
        <Link to="/">Back to Home</Link>
        <br />
        <Link to="/show-submission">Back to Form</Link>
      </StyledPage>
    </Layout>
  )
}

export default ContactSucsessPage
